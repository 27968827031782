import React, { useState, useEffect } from "react";
import './App.css';

import Main from "./containers/Main";
import { ThemeProvider } from "styled-components";
import { themes } from "./theme";
import { GlobalStyles } from "./global";
import { CursorProvider } from "react-cursor-custom";
import { settings } from "./portfolio";

function App() {
  useEffect(() => {

  }, []);
  // const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");
  const [theme, setTheme] = useState("light");
  const useCursor = settings.useCustomCursor;

  return (
    <ThemeProvider theme={themes[theme]}>
      <>
        <GlobalStyles />
        <div>
          <Main theme={themes[theme]} setTheme={setTheme} />
        </div>
      </>
    </ThemeProvider>
  );
}

export default App;
