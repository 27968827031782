import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import ProjectCard from "../../components/ProjectCard/ProjectCard";
import { Fade } from "react-reveal";
import { projectsHeader, projects } from "../../portfolio.js";
import "./Projects.css";
import { style } from "glamor";

const categories = ['All', 'React/Node', 'Blockchain', 'Shopify', 'Python']

function Projects(props) {
  const theme = props.theme;

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)
  const category = searchParams.get('category') || 'All'

  const [activeFilter, setActiveFilter] = useState(category);
  const [filteredProjects, setFilteredProjects] = useState([]);

  const containsCategory = (target, pattern) => {
    var value = 0;
    pattern.forEach(function(word){
      value = value + target.includes(word);
    });
    return (value >= 1)
  }

  useEffect(() => {
    filterProjects(activeFilter.split("/"))
  }, []);

  const filterProjects = (items) => {
    setActiveFilter(items.join("/"));
    if (items[0] === 'All') {
      setFilteredProjects(projects.data);
    } else {
      setFilteredProjects(projects.data.filter((project) => {
        if(project.tags){
          return containsCategory(project.tags.join(), items)
        }
      }));
    }
  }

  const clearSearchString = () => {
    navigate(location.pathname, { replace: true });
  };

  return (
    <div className="projects-main">
      <Header theme={theme} setTheme={props.setTheme} />
      <div className="basic-projects">
        <Fade bottom duration={2000} distance="40px">
          <div className="projects-heading-div">
            <div className="projects-heading-img-div">
              <img src='images/portfolio.webp' style={{ maxWidth: "80%", objectFit: "cover" }}/>
            </div>
            <div className="projects-heading-text-div">
              <h1
                className="projects-heading-text"
                style={{ color: theme.text }}
              >
                {projectsHeader.title}
              </h1>
              <p
                className="projects-header-detail-text subTitle"
                style={{ color: theme.secondaryText }}
              >
                {projectsHeader["description"]}
              </p>
            </div>
          </div>
        </Fade>
      </div>
      <Fade bottom duration={2000} distance="40px">
        <div className="projects-filter">
        {categories.map((category, index) => (
          <button
            key={index}
            className={`button ${activeFilter === category ? 'active' : ''}`}
            onClick={() => {
              clearSearchString()
              filterProjects(category.split("/"))
            }}
          >
            {category}
          </button>
        ))}        
        </div>
      </Fade>
      <div className="repo-cards-div-main">
        {filteredProjects.map((proj) => {
          return <ProjectCard proj={proj} theme={theme} />;
        })}
      </div>
      <br />
      
      <Footer theme={props.theme} onToggle={props.onToggle} />
    </div>
  );
}

export default Projects;
