import React from "react";
import "./ProjectLanguages.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { Icon } from "@iconify/react";

function ProjectLanguages(props) {
  return (
    <div>
      <div className="software-skills-main-div">
        <ul className="dev-icons-languages">
          {props.logos.map((logo) => {
             if(logo.iconifyClass == "") {
              return (
                <li
                  className="software-skill-inline-languages"
                  name={logo.skillName}
                >
                  <img
                    className="logo_img"
                    src={logo.logo_path}
                    style={logo.style}
                  />
                </li>
              );
             } else {
              return (
                <li
                  className="software-skill-inline-languages"
                  name={logo.skillName}
                >
                  <Icon 
                    icon={logo.iconifyClass}
                    style={logo.style}
                    />
                </li>
              );
             }           
          })}
        </ul>
      </div>
    </div>
  );
}

export default ProjectLanguages;
