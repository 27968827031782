import React from "react";
import "./SoftwareSkill.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { Icon } from "@iconify/react";

function SoftwareSkill(props) {
  return (
    <div>
      <div className="software-skills-main-div">
        <ul className="dev-icons">
          {props.logos.map((logo) => {
            if(logo.fontAwesomeClassname == "") {
              return (
                <li className="software-skill-inline" name={logo.skillName}>
                  <img
                    className="logo_img"
                    src={logo.logo_path}
                    style={logo.style}
                  />
                </li>
              );
            } else {
              return (
                <li className="software-skill-inline" name={logo.skillName}>
                  <Icon 
                    icon={logo.fontAwesomeClassname} 
                    style={logo.style}
                  />
                </li>
              );
            }
           
          })}
        </ul>
      </div>
    </div>
  );
}

export default SoftwareSkill;
