import React from 'react';
import * as ReactDOMClient from "react-dom/client";
import { BaseProvider, LightTheme} from "baseui"
import { Provider as StyletronProvider } from "styletron-react"
import { Client as Styletron } from "styletron-engine-atomic"

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./assests/font-awesome/css/all.css";

const engine = new Styletron();

const root = ReactDOMClient.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        <App />          
      </BaseProvider>
    </StyletronProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
