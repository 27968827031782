/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: false, // Change this to false if you want the good'ol cursor
};

//Home Page
const greeting = {
  title: "Hello 👋.",
  title2: "Peter Liu",
  logo_name: "🖥️ Peter Liu",
  logo_stack_label: "/ Full-stack",
  stack_label: "Full stack & blockchain dev",
  full_name: "Peter Liu",
  subTitle:
    "I am a full-stack developer with 8+ years of experience.🔥",
  resumeLink:
    "https://drive.google.com/file/d/17BlRasFBim2xYXkgZf8SsoUnEIi-y6E-/view?usp=sharing",    
  mail: "mailto:peterliusoft@hotmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/fskydev",
  linkedin: "https://www.linkedin.com/in/peter-liu-dev/",
};

const skills = {
  data: [
    {
      title: "Full-stack Development",
      fileName: "FullStackImg",
      skills: [
        "🌻 Skilled in HTML, CSS, JS, TS, React.js",
        "🌻 Familiarity with Hooks, Redux, Next.js",
        "⚙️ Proficient in Node.js, Express, Nest.js",
        "⚙️ Expertise in Websockets, socket.io, WebRTC",
    
        "🧾 Proficient in Rest APIs, and GraphQL",       
        "🧾 Experience with PostgreSQL, MySQL, MongoDB",
        "🧾 Familiarity with serverless architecture",

        // "✨ Utilizes modern tools such as Vite, ESLint, Prettier",
        "✨ Comfortable with Agile methodologies",        
      ],
      softwareSkills: [
        {
          skillName: "React",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "TypeScript",
          fontAwesomeClassname: "vscode-icons:file-type-typescript-official",
        },
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
          color: "#E34F26",
          },
        },
        {
          skillName: "CSS",
          fontAwesomeClassname: "vscode-icons:file-type-css",
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "logos-javascript",
        },
        {
          skillName: "Node",
          fontAwesomeClassname: "vscode-icons:file-type-node",
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "logos:postgresql",
        },
        {
          skillName: "GraphQL",
          fontAwesomeClassname: "simple-icons:graphql",
          style: {
            color: "#DE33A6",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
      ],
    },
    {
      title: "Blockchain Development",
      fileName: "BlockchainImg",
      skills: [
        "🌻 In-depth grasp of PoW and PoS protocols",
        "🌻 Experience with Staking, DeFi, NFT, DApps, DAO",
        "🧾 Skilled in Smart Contract development using Solidity",    
        "✨ Expertise in token standards including ERC20, ERC721",      
        "✨ Familiarity with ethers.js, Alchemy, Infura",
        // "✨ Alchemy, Moralis, Infura, The Graph",
        // "✨ Web3.js, Ethers.js, Wagmi",
      ],
      softwareSkills: [
        {
          skillName: "Ethereum",
          fontAwesomeClassname: "logos:ethereum",
        },
        {
          skillName: "BSC",
          fontAwesomeClassname: "simple-icons:binance",
          style: {
            color: "#000000",
            backgroundColor: "#f3ba2f",
            padding: "4px",
            borderRadius: "50%"
          },
        },
        {
          skillName: "Solidity",
          fontAwesomeClassname: "logos:solidity",
        },
        {
          skillName: "OpenZeppelin",
          fontAwesomeClassname: "logos:open-zeppelin-icon",
        },          
        {
          skillName: "Web3.js",
          fontAwesomeClassname: "logos:web3js",
        },
        {
          skillName: "Ethers.js",
          fontAwesomeClassname: "logos:ethers",
        },
        {
          skillName: "Hardhat",
          fontAwesomeClassname: "logos:hardhat-icon",
        },
        {
          skillName: "Metamask",
          fontAwesomeClassname: "logos:metamask-icon",
        },  
      ],
    },
    {
      title: 'Shopify Store Development',
      fileName: "EcommerceCartImg",
      skills: [
        "⚡ Theme Customization using Liquid, HTML, CSS and JS",
        "⚡ Custom Subscription Development for ReCharge",
        "⚡ Custom App Development using Node & React",
        "⚡ Custom Storefronts Development using Hydrogen",
        "⚡ Shopify Speed Optimization",
      ],
      softwareSkills: [
        {
          skillName: "Shopify",
          fontAwesomeClassname: "logos-shopify",
        },
        {
          skillName: "Liquid",
          fontAwesomeClassname: "vscode-icons:file-type-liquid",
        },
        {
          skillName: "HTML5",
          fontAwesomeClassname: "vscode-icons:file-type-html",
        },
        {
          skillName: "CSS",
          fontAwesomeClassname: "vscode-icons:file-type-css",
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "logos-javascript",
        },
        {
          skillName: "jQuery",
          fontAwesomeClassname: "simple-icons:jquery",
        },

        {
          skillName: "Recharge",
          fontAwesomeClassname: "",
          logo_path: "logo/recharge-logo.png",
          style: {
            height: "48px"
          },
        },
        {
          skillName: "Bold",
          fontAwesomeClassname: "",
          logo_path: "logo/bold-logo.png",
          style: {
            height: "48px"
          },
        },

        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
          color: "#339933",
          },
        },
        {
          skillName: "React",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Next.js",
          fontAwesomeClassname: "logos:nextjs-icon",
          style: {
            color: "#61DAFB",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Dr. S. & S. S. Ghandhy College Of Engineering & Technology",
      subtitle: "Diploma in Information Technology",
      logo_path: "ssgandhy.png",
      alt_name: "SETI",
      duration: "2018 - Present",
      descriptions: [
        "⚡ I have studied core subjects like Data Structures, DBMS, Networking, Security, etc.",
        "⚡ I have also completed various online courses for Backend , Web , Mobile App Development, etc.",
        "⚡ I have implemented several projects based on what I've leart under my Computer Engineering course. ",
      ],
      website_link: "http://www.ssgc.cteguj.in/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "M0001: MongoDB Basics",
      subtitle: "MongoDB University",
      logo_path: "mongo.png",
      certificate_link:
        "https://drive.google.com/file/d/12se6bMep48WXA1PlizTBAgCnW0GXM6wq/view?usp=sharing",
      alt_name: "MongoDB University",
      // color_code: "#2AAFED",
      color_code: "#47A048",
    },
    {
      title: "Hackathon",
      subtitle: "Hack The Mountains",
      logo_path: "hackathon1.svg",
      certificate_link:
        "https://drive.google.com/file/d/12u_tkvhgB-K2TIm-RDdJOwYbQ9ccqiqA/view?usp=sharing",
      alt_name: "hackathon",
      color_code: "#E2405F",
    },
    {
      title: "A300: Atlas Security",
      subtitle: "MongoDB University",
      logo_path: "mongo.png",
      certificate_link:
        "https://drive.google.com/file/d/12u_tkvhgB-K2TIm-RDdJOwYbQ9ccqiqA/view?usp=sharing",
      alt_name: "MongoDB University",
      // color_code: "#F6B808",
      color_code: "#47A048",
    },
    {
      title: "Cyber Security & Cyber Forensics",
      subtitle: "Workshop at IIT Bombay",
      logo_path: "iit.png",
      certificate_link:
        "https://drive.google.com/file/d/12uAdjQC5LfrB1ODdxqIY181ugyyQhl-I/view?usp=sharing",
      alt_name: "Workshop",
      color_code: "#2AAFED",
    },
    {
      title: "MLH Local Hack Day: Build",
      subtitle: "Major League Hacking",
      logo_path: "mlh-logo.svg",
      certificate_link:
        "https://drive.google.com/file/d/1ws7g2Oepu76vDFir6SvR_emFAMSkB2ZQ/view?usp=sharing",
      alt_name: "Google",
      color_code: "#fe0037",
    },
    {
      title: "Hack20",
      subtitle: "Flutter Internation Hackathon",
      logo_path: "flutter.png",
      certificate_link:
        "https://drive.google.com/file/d/1JTd8pVlHbP0RVQYtfa4OZgJYGfdiCwM6/view?usp=sharing",
      alt_name: "Flutter Internation Hackathon",
      color_code: "#2AAFED",
    },
    {
      title: "Postman Student Expert",
      subtitle: "Postman",
      logo_path: "postman.png",
      certificate_link:
        "https://badgr.com/public/assertions/h2qevHs4SlyoHErJQ0mn2g",
      alt_name: "Postman",
      // color_code: "#f36c3d",
      color_code: "#fffbf3",
    },
    // color_code: "#8C151599",
    // color_code: "#7A7A7A",
    // color_code: "#0C9D5899",
    // color_code: "#C5E2EE",
    // color_code: "#ffc475",
    // color_code: "#g",
    // color_code: "#ffbfae",
    // color_code: "#fffbf3",
    // color_code: "#b190b0",
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I've completed one internship. I've mostly done projects on my own and I am actively looking for internships. I love organizing workshops to share my knowledge with others.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "Full Stack Developer (Intern)",
          company: "Valora Infotech",
          company_url: "https://valora-infotech.business.site/",
          logo_path: "valora.jpg",
          duration: "Oct 2020 - Mar 2021",
          location: "Office",
          description:
            "I worked on the Dashboard project which helps users track their activities while using Walo Application. I also worked on Ocean Inventory Application and it's Admin panel Backend as well as on Ocean Inventory Admin Front-end using React and also worked on Walo Admin Backend.",
          color: "#0071C5",
        },
        {
          title: "Full Stack Developer (Freelancer)",
          company: "NightOwls",
          company_url: "http://nightowls.company/",
          logo_path: "nightowls.jpg",
          duration: "Sep 2020 - Oct 2020",
          location: "Work From Home",
          description:
            "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#ee3c26",
        }
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Cross Winter of Code Mentor",
          company: "CWOC",
          company_url: "https://crosswoc.ieeedtu.in/",
          logo_path: "cwoc.png",
          duration: "Feb 2021 - Present",
          location: "Work From Home",
          description:
            "Mentorship responsibilities were to help students plan the project, review issues and pull requests, ensure smooth progress and help them out when they are stuck.",
          color: "#4285F4",
        },
        {
          title: "Campus Hustler",
          company: "Skillenza",
          company_url: "https://skillenza.com/",
          logo_path: "skillenza.png",
          duration: "Feb 2021 - Present",
          location: "Work from Home",
          description: "Spread Awareness of new Technologies and new Opportunities to Students and Grow Skillenza Community.",
          color: "#196acf",
        },
        {
          title: "GitHub Student Developer",
          company: "GitHub",
          company_url: "https://github.com/",
          logo_path: "github.png",
          duration: "Nov 2019 - Present",
          location: "Work from Home",
          description:
          "Contribute to Open Source Community and Open Source Project.",
          color: "#040f26",
        },
        {
          title: "Google Local Guide",
          company: "Google Map",
          company_url: "https://maps.google.com/localguides/",
          logo_path: "localguide.png",
          duration: "Sep 2018 - Present",
          location: "Work From Home",
          description:
            "Day-to-day responsibilities of helping local businesses to spread their business to the world. Helping users by writing reviews about different locations and spaces such as shops, malls, etc.",
          color: "#D83B01",
        },
        {
          title: "GDG Student Volunteer",
          company: "Google Developer Groups",
          company_url: "https://gdg.community.dev/",
          logo_path: "gdg.png",
          duration: "Feb 2021 - Present",
          location: "Work From Home",
          description:
            "Google Developer Group Surat Student Volunteer and Member.",
          color: "#D83B01",
        },
        {
          title: "E. F. I. Student Volunteer",
          company: "ENVIRONMENTALIST FOUNDATION OF INDIA",
          company_url: "https://indiaenvironment.org/",
          logo_path: "efi.png",
          duration: "Apr 2017 - Present",
          location: "Work From Home",
          description:
            "Take time out over weekends to join a gang of nation builders, nature enthusiasts to conserve what is left.",
          color: "#5a900f",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Portfolio Showcase",
  description:
    "Explore my previous work and examples to see how I excel in creating fast and user-friendly websites.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I don't blog frequently but when I do something awesome, I do try to document it so it can be helpful to others. I write on Twitter.",
    link: "https://twitter.com/Harikrushn9",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      id: "1",
      name: "MERN Auth & Chat App",
      image: "images/screenshot_mern_auth_chat.webp",
      liveUrl: "https://mern-auth-chat.onrender.com/",
      repoUrl: "https://github.com/fskydev/mern-auth-chat",
      description: "This is a perfect example for a MERN stack real-time chat application with authentication & authorization.",
      languages: [
        {
          name: "Node",
          iconifyClass: "vscode-icons:file-type-node",
        },
        {
          name: "Express",
          iconifyClass: "devicon:express",
        },
        {
          name: "JWT",
          iconifyClass: "logos:jwt-icon",
        },
        {
          name: "Socket.IO",
          iconifyClass: "devicon:socketio",
        },
        {
          name: "MongoDB",
          iconifyClass: "devicon:mongodb",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "React Router",
          iconifyClass: "devicon:reactrouter",
        },
        {
          name: "TailwindCSS",
          iconifyClass: "devicon:tailwindcss",
        },
      ],
      tags: [
        "Node",
        "Express",
        "JWT",
        "Socket.IO",
        "MongoDB",
        "React",
        "React Router",
        "TailwindCSS"
      ],
    },
    {
      id: "1",
      name: "wasm-tetris",
      image: "images/screenshot_wasm_tetris.webp",
      liveUrl: "https://wasm-tetris.netlify.app/",
      repoUrl: "https://github.com/fskydev/wasm-tetris",
      description: "Tetris clone in Rust and WebAssembly.",
      languages: [
        {
          name: "Rust",
          iconifyClass: "logos:rust",
        },
        {
          name: "WebAssembly",
          iconifyClass: "vscode-icons:file-type-wasm",
        }
      ],
      tags: [
        "Rust",
        "WebAssembly",
      ],
    },
    {
      id: "2",
      name: "ParagonsDAO",
      image: "images/paragonsdao.webp",
      liveUrl: "https://paragonsdao.com/",
      description: "ParagonsDAO is an economic and social partner-DAO to promising blockchain-powered gaming ecosystems.",
      languages: [
        {
          name: "Ethereum",
          iconifyClass: "logos-ethereum",
        },
        {
          name: "Solidity",
          iconifyClass: "logos-solidity",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "TypeScript",
          iconifyClass: "vscode-icons:file-type-typescript-official",
        },
        {
          name: "Ethers.js",
          iconifyClass: "logos:ethers",
        },
      ],
      tags: [
        "Blockchain",
        "Ethereum",
        "React",
        "TypeScript"
      ],
    },
    {
      id: "19",
      name: "GoPulse.com",
      image: "images/screenshot_gopulse.webp",
      liveUrl: "https://gopulse.com/",
      description: "The PulseChain Suite with on-chain statistics, PLS on-ramp, Portfolio Tracker, HEX Frontend and Trading Platform.",
      languages: [
        {
          name: "Ethereum",
          iconifyClass: "logos-ethereum",
        },
        {
          name: "Solidity",
          iconifyClass: "logos-solidity",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Next.js",
          iconifyClass: "logos:nextjs-icon"
        },
        {
          name: "Ethers.js",
          iconifyClass: "logos:ethers",
        },
      ],
      tags: [
        "Blockchain",
        "Ethereum",
        "React",
        "Solidity"
      ],
    },
    {
      id: "1",
      name: "ShipAid ‑ Shipping Guarantee",
      image: "images/shipaid_customer_portal.webp",
      liveUrl: "https://www.shipaid.com/",
      description: "ShipAid empowers e-commerce businesses to streamline shipping, enhance customer satisfaction, and improve post-purchase experience.",
      languages: [
        {
          name: "Node",
          iconifyClass: "vscode-icons:file-type-node",
        },
        {
          name: "AdonisJS",
          iconifyClass: "devicon:adonisjs",
        },
        {
          name: "Shopify",
          iconifyClass: "logos-shopify",
        },        
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Remix",
          iconifyClass: "logos:remix-icon",
        },
        {
          name: "TypeScript",
          iconifyClass: "vscode-icons:file-type-typescript-official",
        },
        {
          name: "Nhost",
          iconifyClass: "logos:nhost-icon",
        },
        {
          name: "PostgreSQL",
          iconifyClass: "logos:postgresql",
        },
        {
          name: "GraphQL",
          iconifyClass: "simple-icons:graphql",
          style: {
            color: "#DE33A6",
          },
        },
      ],
      tags: [
        "React",
        "TypeScript",
        "Node",
        "Shopify"
      ],
    },
    // {
    //   id: "3",
    //   name: "The Priming - NFT Aggregator",
    //   image: "images/thepriming.webp",
    //   liveUrl: "https://www.thepriming.io/",
    //   description: "The Priming is a portal into the Parallel NFT universe. It provides statistics on secondary market activity, sales history, wallet valuation, and more.",
    //   languages: [
    //     {
    //       name: "Ethereum",
    //       iconifyClass: "logos-ethereum",
    //     },
    //     {
    //       name: "Ethers.js",
    //       iconifyClass: "logos:ethers",
    //     },
    //     {
    //       name: "ASP.NET Core",
    //       iconifyClass: "devicon:dotnetcore",
    //     },
    //     {
    //       name: "C#",
    //       iconifyClass: "devicon:csharp",
    //     },
    //     {
    //       name: "Razor Pages",
    //       iconifyClass: "vscode-icons:file-type-razor",
    //     },
    //     {
    //       name: "Tailwind",
    //       iconifyClass: "vscode-icons:file-type-tailwind",
    //     },
    //     // {
    //     //   name: "daisyUI",
    //     //   iconifyClass: "logos:daisyui",
    //     // },
    //     {
    //       name: "Azure",
    //       iconifyClass: "devicon:azure",
    //     },
    //     {
    //       name: "Node",
    //       iconifyClass: "vscode-icons:file-type-node",
    //     },
    //   ],
    //   tags: [
    //     "ASP.NET",
    //     "Ethereum",
    //     "NFT",
    //   ],
    // },
    {
      id: "2",
      name: "NFT Minting project",
      image: "images/screenshot_mint_10k.webp",
      liveUrl: "https://solana-cmv2-mint-presale-10k-ui.vercel.app/",
      repoUrl: "https://github.com/fskydev/solana-cmv2-mint-presale-10k",
      description: "Example of creating an NFT collection (10,000+) with Mint & Presale on Solana.",
      languages: [
        {
          name: "Rust",
          iconifyClass: "logos:rust",
        },
        {
          name: "Solana",
          iconifyClass: "cryptocurrency:sol",
        },
        {
          name: "Metaplex",
          iconifyClass: "",
          logo_path: "logo/meta-white.svg",
          style: {
            height: "30px"
          },
        },
      ],
      tags: [
        "Blockchain",
        "Rust",
        "Solana",
      ],
    },
    {
      id: "3",
      name: "LRE Social",
      image: "images/01-lresocial-homepage-01-hero.webp",        
      liveUrl: "https://www.lresocial.com/",
      description:
        "Empowering women-owned real estate businesses.",
      languages: [
        {
          name: "Babel",
          iconifyClass: "logos:babel",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "NodeJS",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "AWS S3",
          iconifyClass: "simple-icons:amazonaws",
          style: {
            color: "#ff9900",
          },
        },          
      ],
      tags: [
        "React"
      ],
    },
    {
      id: "4",
      name: "NW Storefront",
      image: "images/screenshot_nextworld.webp",        
      liveUrl: "https://map-dev.vercel.app/product/create-a-map",
      repoUrl: "https://github.com/fskydev/react-next-world",
      description:
        "Polished up the map maker, created frame layouts that overlay the poster, and updated product options.",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Next.js",
          iconifyClass: "logos:nextjs-icon"
        },
        {
          name: "TypeScript",
          iconifyClass: "vscode-icons:file-type-typescript-official",
        },
        {
          name: "Shopify",
          iconifyClass: "logos-shopify",
        }
      ],
      tags: [
        "React",
        "TypeScript"
      ],
    },
    {
      id: "5",
      name: "LitJoy",
      image: "images/screenshot_litjoycrate.webp",
      liveUrl: "https://litjoycrate.com/",
      description: "Customized many pages, including Homepage, Collection page, Product page, etc using Liquid, HTML, CSS, JavaScript, and jQuery.",
      languages: [
        {
          name: "Shopify",
          iconifyClass: "logos-shopify",
        },
        {
          name: "Liquid",
          iconifyClass: "vscode-icons:file-type-liquid",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "jQuery",
          iconifyClass: "simple-icons:jquery",
          style: {
            color: "#0865A6",
          },
        }
      ],
      tags: [
        "Shopify"
      ],
    },
    {
      id: "6",
      name: "ORLY",
      image: "images/screenshot_orly_beauty.webp",
      liveUrl: "https://orlybeauty.com/collections/all-nail-colors",
      repoUrl: "https://github.com/fskydev/shopify-theme-orly-beauty",
      description: "Created a custom collection template and the multi-level navigation menu. Customized the search results page.",
      languages: [
        {
          name: "Shopify",
          iconifyClass: "logos-shopify",
        },
        {
          name: "Liquid",
          iconifyClass: "vscode-icons:file-type-liquid",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "jQuery",
          iconifyClass: "simple-icons:jquery",
          style: {
            color: "#0865A6",
          },
        }
      ],
      tags: [
        "Shopify"
      ],
    },
    {
      id: "7",
      name: "Saturo",
      image: "images/screenshot_saturo.webp",
      liveUrl: "https://saturo.com/",
      repoUrl: "https://github.com/fskydev/shopify-structured-data-saturo",
      description: "Added structured data to pages.",
      languages: [
        {
          name: "Shopify",
          iconifyClass: "logos-shopify",
        },
        {
          name: "Liquid",
          iconifyClass: "vscode-icons:file-type-liquid",
        },
        {
          name: "SEO",
          iconifyClass: "icon-park:seo",
        },
      ],
      tags: [
        "Shopify",
        "SEO"
      ],
    },
    {
      id: "8",
      name: "VIVE HEALTH",
      image: "images/screenshot_vivehealth_products.webp",
      liveUrl: "https://www.vivehealth.com/",
      description: "Customized Homepage, some landing pages, Mobile Navigation, etc by creating custom sections. Created a new custom product page template.",
      languages: [
        {
          name: "Shopify",
          iconifyClass: "logos-shopify",
        },
        {
          name: "Liquid",
          iconifyClass: "vscode-icons:file-type-liquid",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "jQuery",
          iconifyClass: "simple-icons:jquery",
          style: {
            color: "#0865A6",
          },
        }
      ],
      tags: [
        "Shopify"
      ],
    },
    {
      id: "9",
      name: "Todo App",
      image: "images/screenshot_todo.webp",
      liveUrl: "https://solana-todoapp.netlify.app/",
      repoUrl: "https://github.com/fskydev/solana-todo-app",
      description: "Solana Todo App with Rust(Anchor).",
      languages: [
        {
          name: "Rust",
          iconifyClass: "logos:rust",
        },
        {
          name: "Solana",
          iconifyClass: "cryptocurrency:sol",
        },
        {
          name: "Anchor",
          iconifyClass: "noto:anchor",
        },
      ],
      tags: [
        "Blockchain",
        "Rust",
        "WebAssembly",
      ],
    },
    {
      id: "10",
      name: "Frypt",
      image: "images/frypt.webp",
      repoUrl: "https://github.com/fskydev/buy_sell_crypto_coins",
      description: "Example of a Web 3.0 Blockchain Application.",
      languages: [
        {
          name: "Ethereum",
          iconifyClass: "logos-ethereum",
        },
        {
          name: "Solidity",
          iconifyClass: "logos-solidity",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Ethers.js",
          iconifyClass: "logos:ethers",
        },
      ],
      tags: [
        "Blockchain",
        "Ethereum",
        "React",
        "Solidity"
      ],
    },
    {
      id: "11",
      name: "AutoMata",
      image: "images/automata3d.webp",
      repoUrl: "https://github.com/fskydev/automata3d",
      description: "Powerful Web3 Development for advanced technology.",
      languages: [
        {
          name: "Laravel",
          iconifyClass: "logos:laravel",
        },
        {
          name: "SCSS",
          iconifyClass: "vscode-icons:file-type-scss",
        },
        {
          name: "SEO",
          iconifyClass: "icon-park:seo",
        },          
      ],
      tags: [
        "Laravel",
        "SEO"
      ],
    },
    {
      id: "12",
      name: "List-YouTube",
      image: "images/screenshot_listtube.webp",
      repoUrl: "https://github.com/fskydev/List-YouTube",
      description: "Automatically create and post YouTube playlists and videos.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Selenium",
          iconifyClass: "logos-selenium",
        },
        {
          name: "Pandas",
          iconifyClass: "simple-icons:pandas",
          style: {
            color: "#1488C6",
          },
        }
      ],
      tags: [
        "Python",
        "Scraping"
      ],
    },
    {
      id: "13",
      name: "Web Scraping",
      image: "images/screenshot_excel.webp",
      repoUrl: "https://github.com/fskydev/web-scraping/blob/master/main.py",
      description: "Extract data from dkfnet.dk/medlemsliste/ & export the information to an excel file.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Selenium",
          iconifyClass: "logos-selenium",
        },
        {
          name: "Pandas",
          iconifyClass: "simple-icons:pandas",
          style: {
            color: "#1488C6",
          },
        }
      ],
      tags: [
        "Python",
        "Scraping"
      ],
    },
    {
      id: "14",
      name: "Smart Control Horario",
      image: "images/screenshot_clockinout.webp",
      liveUrl: "https://play.google.com/store/apps/details?id=io.sch.clockinout",
      repoUrl: "https://github.com/fskydev/ClockInOut",
      description: "The software for the attendance control of the employees of your organization.",
      languages: [
        {
          name: "React Native",
          iconifyClass: "logos-react",
        },
        {
          name: "Geolocation",
          iconifyClass: "healthicons:geo-location",
          style: {
            color: "#960a0a",
          },
        },          
      ],
      tags: [
        "React Native"
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
