import React, { useState } from "react";
import ProjectLanguages from "../projectLanguages/ProjectLanguages";
import "./ProjectCard.css";
import { Fade } from "react-reveal";
import { style } from "glamor";
import {Card, StyledBody, StyledAction} from 'baseui/card';
import { StyledLink } from "baseui/link";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p style={{ margin: "0" }}>
      { isReadMore ? text.slice(0, 86) : text}
      { isReadMore && text.length > 85 &&
        <span onClick={toggleReadMore} className="read-or-hide" style={{ cursor: "pointer", fontSize: "14px", color: "gray" }}>
          &nbsp;...read more
        </span>
      }      
    </p>
  );
};

export default function ProjectCard({ proj, theme }) {
  function openRepoinNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  return (
    <div>
      <Fade bottom duration={2000} distance="40px">
        <Card
          overrides={{
            Root: {
              style: {
                backgroundColor: theme.projectCard,
                boxShadow: "rgba(0, 0, 0, 0.2) 0px 10px 30px -15px",
                // cursor: "pointer",
                borderColor: theme.imageDark,
                // borderWidth: "0px",
                transition: "all 0.2s ease-in-out",
                ":hover": {
                  // boxShadow: `${theme.imageDark} 0 2px 15px`,
                  // borderWidth: "2px",
                  borderColor: '#fb1056',
                  boxShadow: '0 2px 15px #fb1056',                
                },
              }
            }
          }}
        >
          <StyledBody style={{ color: theme.text }}>
            <div style={{ height: "280px", overflow: "hidden", marginBottom: "20px" }}>
              <img src={proj.image} style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "4px" }}/>
            </div>
            <div className="repo-name-div">
              <p className="repo-name" style={{ color: theme.text }}>
                {proj.name}
              </p>
            </div>
            <p className="repo-description" style={{ color: theme.text }}>
              <ReadMore>
                {proj.description}
              </ReadMore>
            </p>
            <div className="repo-details">
              <ProjectLanguages logos={proj.languages} />
            </div>
          </StyledBody>
          <StyledAction style={{ color: theme.text, display: "flex", flexDirection: "row" }}>
            { proj.liveUrl &&
              <StyledLink href={proj.liveUrl} target="_blank" style={{ color: theme.text, marginRight: "10px" }}>
                Live
              </StyledLink>
            }
            { proj.repoUrl &&
              <StyledLink href={proj.repoUrl} target="_blank" style={{ color: theme.text }}>
                Repo
              </StyledLink>
            }
          </StyledAction>
        </Card>
      </Fade>
    </div>
  );
}
